import { Component, OnInit, ɵConsole } from '@angular/core';
import { PopoverController, ToastController, AlertController, Platform } from '@ionic/angular';
import { FacturasService } from 'src/app/services/facturas.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
//File
import { File } from '@ionic-native/file/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver/dist/FileSaver';
import { FELObj } from '../../models/fel/felobj';
import { resultado_certificacion } from '../../models/fel/resultado_certificacion';
import { anulacionbj } from '../../models/fel/anulacionobj';
import { FelService } from 'src/app/services/fel.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Epay } from 'src/app/models/epay';
import { EpayService } from 'src/app/services/epay.service';
import { Transaccion } from 'src/app/models/transaccion';
import { EpayResponse } from 'src/app/models/epay-response';
import { EpayAnulacion } from 'src/app/models/epay-anulacion';
import { stringify } from '@angular/compiler/src/util';
@Component({
  selector: 'app-pop-transacciones',
  templateUrl: './pop-transacciones.component.html',
  styleUrls: ['./pop-transacciones.component.scss'],
})
export class PopTransaccionesComponent implements OnInit {

  objeto: any;
  isNative: Boolean;
  epayObj: any;
  viendoTransaccion = false;
  epayresponseObj = new EpayResponse("","", "", "", "", "");
  epayAnulaObj = new EpayAnulacion(0);
  cuotas: string = "";
  horarioAnulacion: boolean;
  FechaActual: Date;
  FechaTransaccion: Date;
  facturaExito = false;

  constructor(private popCtrl: PopoverController,
              private toastCtrl: ToastController,
              private facturaService: FelService,
              private alertController: AlertController,
              private router: Router,
              private platform: Platform,
              private file: File,
              private ft: FileTransfer,
              private fileOpener: FileOpener,
              private document: DocumentViewer,
              private transfer: FileTransfer, 
              private files: File,
              private http: HttpClient,
              private loading: LoadingService,
              private epayService: EpayService
              ) { }

  ngOnInit() {
    if(localStorage.getItem("isNative")=="false"){
      this.isNative=false;
    }else{
      this.isNative=true;
    }
    //console.log('isNative : ',this.isNative);
    this.FechaActual = new Date();
      this.FechaTransaccion = new Date(this.objeto.fecha);
      
      if(this.FechaActual.getDate() != this.FechaTransaccion.getDate())
      {
        this.horarioAnulacion = false;
      }
      else if(this.FechaActual.getHours() >= 22)
      {
        this.horarioAnulacion = false;
      }
      else
      {
        this.horarioAnulacion = true;
      }
      
  }

  ionViewDidEnter() {

      this.FechaActual = new Date();
      this.FechaTransaccion = new Date(this.objeto.fecha);
      
      if(this.FechaActual.getDate() != this.FechaTransaccion.getDate())
      {
        this.horarioAnulacion = false;
      }
      else if(this.FechaActual.getHours() >= 22)
      {
        this.horarioAnulacion = false;
      }
      else
      {
        this.horarioAnulacion = true;
      }

      let objTransaccion : Transaccion = this.objeto;
      this.facturaExito = objTransaccion.resultadoFel;

  }

  async presentToast(mensaje: string) {
    const toast = await this.toastCtrl.create({
      message: mensaje,
      duration: 2000
    });
    toast.present();
  }

  verTransaccion(transaccion: Transaccion)
  {
    this.epayresponseObj = JSON.parse(transaccion.resultadoJson);
    this.epayObj = JSON.parse(transaccion.peticionOfuscada);

    console.log(this.epayresponseObj);

    if(this.epayObj.AdditionalData != "" && transaccion.origenPago != 6)
      this.cuotas = this.epayObj.AdditionalData.substr(2, 2);

    // console.log(this.cuotas);
    this.viendoTransaccion = true;
   


  }


  AnularRequest(transaccion)
  {
    
    this.presentAlertAnular(transaccion);
 
  }

 
  async presentAlertAnular(transaccion) {    

 

    const alert = await this.alertController.create({
      header: 'Anular transacción',
      message: ' <p style="font-style: normal; font-weight: bold; font-size: 17.904px; line-height: 24px;color: #4A4D4E">¿Estás seguro de anular la transacción de ' + transaccion.nombre + ' ?</p>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //no desea anular
            this.popCtrl.dismiss();
          }
        }, {
          text: 'Anular',
          handler: () => {
              //si desea anular transaccion
              this.loading.present();
              //se extrae del objeto transaccion el objeto json de epay y se convierte   
              this.epayAnulaObj.transaccion = transaccion.transaccionId;
              this.epayService.requestEpayAnular(this.epayAnulaObj).subscribe(data=>{
                
                if(data.responseCode === "00")
                {
                  transaccion.estado = 2;
                  this.loading.dismiss();
                  this.presentToast(data.responseMessage);

                }
                else
                {
                  
                  this.loading.dismiss();
                  this.presentToast(data.responseMessage);
                }
                
              });
  
              this.popCtrl.dismiss();
      
             
          }
        }
      ]
    });

    await alert.present();
  }

  descargarPDF(transaccion: Transaccion)
  {
    //console.log(factura);
   let downloadUrl = 'https://files.swigit.gt/' + localStorage.getItem("nit_emisor") + '/' + transaccion.factura + '.pdf';

   let path = this.file.dataDirectory;
    const transfer = this.ft.create(); //download the file

    if(this.isNative == false || this.platform.is('mobileweb')==true){
      window.open(downloadUrl);
    } else {

      transfer.download(downloadUrl, `${path}` + transaccion.factura + '.pdf')
        .then(entry => {
          let url = entry.toURL();
          if(this.platform.is('ios')){
            this.document.viewDocument(url,'application/pdf', {});
          } else if(this.platform.is('android')){
            this.fileOpener.open(url, 'application/pdf');
          } 
        });
    }
  }


}
